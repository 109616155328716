<template>
  <main class="h-auto container mx-auto px-4 main">
    <div class="flex flex-wrap items-end py-6 sm:py-16">
      <div class="w-full sm:w-2/3">
        <h1 class="font-bold mb-4 text-2xl">
          <span v-html="pageData.title"></span>
        </h1>
        <p class="w-full sm:w-3/4">
          <span v-html="pageData.default_content"></span>
        </p>
      </div>
      <div class="w-full sm:w-1/3 text-right mt-8 sm:mt-0">
        <a :href="globalValues.social_links.linkedin" target="_blank">
          <i class="fab fa-linkedin text-3xl mr-4 cursor-pointer"></i>
        </a>
        <a :href="globalValues.social_links.facebook" target="_blank">
          <i class="fab fa-facebook-square text-3xl mr-4 cursor-pointer"></i>
        </a>
        <a :href="globalValues.social_links.instagram" target="_blank">
          <i class="fab fa-instagram-square text-3xl cursor-pointer"></i>
        </a>
      </div>
    </div>
    <div class="team">
      <div class="flex flex-wrap sm:flex-row">
        <div v-if="pageData.custom_content.banner"
            class="w-full ratio6_1 border-2 border-white relative bg-cover bg-center lg:hidden"
            :style="`background-image: url('${pageData.custom_content.banner}');`">

        </div>

        <div v-if="pageData.custom_content.video_banner" class="w-full hidden lg:block">
          <div class="wrapper relative">
            <div class="iframe-overlay bg-black bg-opacity-25 absolute inset-0 h-full w-full"></div>
            <div class="frame-container">
              <iframe id="ytplayer" type="text/html" width="100%" height="650" :src="`https://www.youtube.com/embed/${pageData.custom_content.video_banner}?rel=0&autoplay=1&controls=0&disablekb=1&showinfo=0&autohide=1&loop=1&playlist=${pageData.custom_content.video_banner}&playsinline=1&mute=1`" allow="autoplay;" frameborder="0"></iframe>
            </div>
          </div>
        </div>

      </div>
      <div class="flex flex-wrap sm:flex-row">
        <div
            :key="member.name" v-for="member in pageData.custom_content.team"
            class="w-full sm:w-1/2 mb-4 ratio1_1 border-2 border-white bg-no-repeat bg-cover bg-center relative"
            :style="`background-image: url('${member.background}');`"
        >
          <span class="team-name"> {{ member.name }} </span>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getPage } from '@/services/Wordpress/Get'
import { mapGetters } from 'vuex'


export default {
  metaInfo: {
    title: 'Wij zijn een Tikkl anders | Tikkl | Een grafisch bureau dat kietelt',
    meta: [
      { charset: 'utf-8' },
      {
        name: 'keywords',
        content: 'TIKKL, Uden'
      },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        hid: 'description',
        name: 'description',
        content: 'Wij zijn Tikkl - een grafisch bureau dat kietelt met doordachte huisstijlen en verrassende concepten'
      },
      {
        property: 'og:title',
        content: 'Wij zijn een Tikkl anders | Tikkl | Een grafisch bureau dat kietelt'
      },
      {
        property: 'og:description',
        content: 'Wij zijn Tikkl - een grafisch bureau dat kietelt met doordachte huisstijlen en verrassende concepten'
      },
      { property: 'og:url', content: 'https://tikkl.nl/de-mensen' }
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico'
      }
    ],
    htmlAttrs: {
      lang: 'nl',
      amp: true
    }
  },
  data() {
    return {
      pageData: {
        title: '',
        default_content: '',
        custom_content: {},
        seo: {},
        video: null
      }
    }
  },
  computed: mapGetters(['globalValues']),
  async created () {
    this.pageData = await getPage({ slug: this.$route.path })
  },
  mounted() {

  }
}
</script>
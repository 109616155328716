<template>
  <div class="flex flex-wrap sm:flex-row">
    <div class="w-full sm:w-1/2">
      <div class="flex flex-row">
        <div class="flex flex-row w-full">
          <div v-if="data[0]" class="w-1/2 ratio1_1 border-2 border-white bg-cover bg-center bg-no-repeat" :style="`background-image: url('${data[0].url}');`">

          </div>
          <div v-if="data[1]" class="w-1/2 ratio1_1 border-2 border-white bg-cover bg-center bg-no-repeat" :style="`background-image: url('${data[1].url}');`">

          </div>
        </div>
      </div>
      <div v-if="data[2]" class="w-full ratio2_1 border-2 border-white bg-cover bg-center bg-no-repeat" :style="`background-image: url('${data[2].url}');`">

      </div>
    </div>
    <div class="flex flex-row w-full sm:w-1/2">
      <div v-if="data[3]" class="w-full ratio1_1 border-2 border-white bg-cover bg-center bg-no-repeat" :style="`background-image: url('${data[3].url}');`">

      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      required: false
    }
  }
}
</script>
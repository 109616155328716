<template>
  <main class="container mx-auto mt-16 mb-8 sm:mt-64 sm:mb-40">
    <div id="contact" class="text-center">
      <form v-on:submit.prevent="contact" id="contact">
        <h1 class="font-bold text-2xl pb-8">Contact opnemen!</h1>
        <p class="contact-text leading-8">Hallo, mijn naam is <input v-model="contactName" type="text"
                                                                     placeholder="naam" name="naam" class="inline"
                                                                     required> en ik wil<br
            class="hidden sm:inline-block"/> graag meer informatie over jullie werk en mogelijkheden.<br
            class="hidden sm:inline-block"/> Jullie kunnen mij mailen via <input v-model="contactEmail" type="email"
                                                                                 placeholder="e-mailadres"
                                                                                 name="emailadres" class="inline"
                                                                                 required><br
            class="hidden sm:inline-block"/> of even bellen naar <input v-model="contactPhone" type="tel"
                                                                        placeholder="telefoonnummer"
                                                                        name="telefoonnummer" class="inline" required>
        </p>
        <button type="submit" :disabled="this.submitting"
                class="bg-black text-white uppercase font-bold mt-8 px-4 py-1">Neem contact met mij op
        </button>
        <br/>
        <button onclick="location.href='https://goo.gl/maps/pTv1771e2UG2'" target="_blank"
                class="text-black uppercase font-bold px-4 py-1">Bekijk route
        </button>

        <div v-if="errorMessage" v-show="errorMessage.length > 0" class="row flex-row mt-4">
          <div class="w-full text-center">
            <p style="color: red;" v-html="errorMessage"></p>
          </div>
        </div>
        <div v-if="successMessage" v-show="successMessage.length > 0" class="row flex-row mt-4">
          <div class="w-full text-center">
            <p style="color: green;" v-html="successMessage"></p>
          </div>
        </div>

      </form>
    </div>
  </main>
</template>

<script>
import axios from 'axios'

export default {
  metaInfo: {
    title: 'Neem contact met ons op | Tikkl | Een grafisch bureau dat kietelt',
    meta: [
      { charset: 'utf-8' },
      {
        name: 'keywords',
        content: 'TIKKL, Uden'
      },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        hid: 'description',
        name: 'description',
        content: 'Neem contact op met Tikkl - een grafisch bureau dat kietelt met doordachte huisstijlen en verrassende concepten'
      },
      {
        property: 'og:title',
        content: 'Neem contact met ons op | Tikkl | Een grafisch bureau dat kietelt'
      },
      {
        property: 'og:description',
        content: 'Neem contact op met Tikkl - een grafisch bureau dat kietelt met doordachte huisstijlen en verrassende concepten'
      },
      { property: 'og:url', content: 'https://tikkl.nl/contact' }
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico'
      }
    ],
    htmlAttrs: {
      lang: 'nl',
      amp: true
    }
  },
  data() {
    return {
      contactName: null,
      contactPhone: null,
      contactEmail: null,
      submitting: false,
      errorMessage: '',
      successMessage: '',
    }
  },

  methods: {
    contact() {

      let params = new URLSearchParams();
      params.append('contact-name', this.contactName);
      params.append('contact-phone', this.contactPhone);
      params.append('contact-email', this.contactEmail);

      this.submitting = true

      axios.post('https://cms.tikkl.nl/wp-json/contact-form-7/v1/contact-forms/6/feedback', params, {'Content-Type': 'application/x-www-form-urlencoded'}).then((data) => {
        this.successMessage = 'Bericht is verstuurd, we nemen zo snel mogelijk contact met je op!';

        this.contactName = this.contactEmail = this.contactPhone = null;
        this.submitting = false;

      })
          .catch(() => {
            this.errorMessage = 'Bericht is niet verstuurd, probeer het nogmaals...';
            this.submitting = false;
          })
    },
  }
}

</script>
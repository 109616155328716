<template>
  <main v-if="loaded" class="py-12">

    <div class="container relative mx-auto">
      <div class="absolute right-0 date-blog-item date-blog-item__inner absolute mr-4 text-white text-l sm:text-2xl bg-black font-bold" style="z-index: 5">
        <span class="p-4"v-html="pageData.custom_content.date_item.replace(/\//g, '.')"></span>
      </div>
    </div>


    <div class="relative">
      <div
          class="w-full ratio4_1 bg-center bg-no-repeat bg-cover"
          :style="`background-image: url('${pageData.custom_content.featured_image}');`"
      >
      </div>
    </div>
    <div class="my-20 container mx-auto text-center px-4">
      <h1 class="font-bold text-2xl">
        <span v-html="pageData.title"></span>
      </h1>
      <p class="w-full sm:w-2/3 mx-auto pt-8 pb-16 blog_content">
        <span v-html="pageData.custom_content.content"></span>
      </p>

      <template v-for="row in pageData.custom_content.blog_images">
        <img :src="row['afbeelding']" class="w-full h-auto mb-4" />
      </template>
      <template v-if="pageData.custom_content.collaboration_text">
        <h2 class="font-bold pt-12">in samenwerking met:</h2>
        <p class="w-full sm:w-1/3 mx-auto pt-2">
          <span v-html="pageData.custom_content.collaboration_text"></span>
        </p>
      </template>
      <template v-if="pageData.custom_content.collaboration_client">
        <h3 class="font-bold mt-4">opdrachtgever:&nbsp;<span class="font-normal"><span v-html="pageData.custom_content.collaboration_client"></span></span></h3>
      </template>
    </div>
    <div class="flex flex-row container mx-auto px-4">
      <div class="w-1/3 previous uppercase">
        <a v-if="currentPage !== 0 && typeof blog[currentPage - 1] !== 'undefined'"  :href="blog[currentPage - 1].slug">< <span class="hidden sm:inline-block prev-text">vorige</span></a>
      </div>
      <div class="w-1/3 summary uppercase text-center">
        <a href="/de-blog">overzicht</a>
      </div>
      <div class="w-1/3 next uppercase text-right">
        <a v-if="currentPage !== blog.length && typeof blog[currentPage + 1] !== 'undefined'" :href="blog[currentPage + 1].slug"><span class="hidden sm:inline-block next-text">volgende</span> ></a>
      </div>
    </div>
  </main>
</template>

<script>
import { getPostBySlug } from '@/services/Wordpress/Get'
import { mapGetters } from 'vuex'

export default {
  metaInfo: {
    title: 'Bekijk ons blogs | Tikkl | Een grafisch bureau dat kietelt',
    meta: [
      { charset: 'utf-8' },
      {
        name: 'keywords',
        content: 'TIKKL, Uden'
      },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        hid: 'description',
        name: 'description',
        content: 'Wij zijn trots op onze doordachte huisstijlen en verrassende concepten - Tikkl - een grafisch bureau dat kietelt met doordachte huisstijlen en verrassende concepten'
      },
      {
        property: 'og:title',
        content: 'Bekijk ons blogs | Tikkl | Een grafisch bureau dat kietelt'
      },
      {
        property: 'og:description',
        content: 'Wij zijn trots op onze doordachte huisstijlen en verrassende concepten - Tikkl - een grafisch bureau dat kietelt met doordachte huisstijlen en verrassende concepten'
      },
      { property: 'og:url', content: 'https://tikkl.nl/de-blog' }
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico'
      }
    ],
    htmlAttrs: {
      lang: 'nl',
      amp: true
    }
  },
  data() {
    return {
      pageData: null,
      loaded: false,
      currentPage: null
    }
  },

  watch: {
    pageData() {
      this.loaded = true;
    },
  },

  mounted() {
    this.setCurrentPage();
  },

  computed: mapGetters(['globalValues','blog']),

  async created () {
    this.pageData = await getPostBySlug({slug: this.$route.params.slug});
  },

  methods: {

    /**
     * Determine the currentPage based on the slug.
     */
    setCurrentPage() {
      setTimeout(() => {
        Object.keys(this.blog).forEach(blogIndex => {
          if (this.blog[blogIndex].slug === this.$route.params.slug) {
            this.currentPage = parseInt(blogIndex);
          }
        });
      }, 1000);
    }
  }
}
</script>
<template>
  <div class="flex flex-wrap sm:flex-row">
    <div
        v-if="data[0]"
        class="order-2 sm:order-1 w-1/2 sm:w-1/4 ratio1_1 border-2 border-white relative bg-cover bg-center bg-no-repeat"
        :style="`background-image: url('${data[0].custom_content.featured_image}');`"
    >
      <a :href="`/blog/${data[0].slug}`" class="absolute inset-0"></a>
    </div>
    <div
        v-if="data[1]"
        class="order-1 sm:order-2 w-full sm:w-2/4 ratio2_1 border-2 border-white relative bg-cover bg-center bg-no-repeat"
        :style="`background-image: url('${data[1].custom_content.featured_image}');`"
    >
      <a :href="`/blog/${data[1].slug}`" class="absolute inset-0"></a>
    </div>
    <div
        v-if="data[2]"
        class="order-3 w-1/2 sm:w-1/4 ratio1_1 border-2 border-white relative bg-cover bg-center bg-no-repeat"
        :style="`background-image: url('${data[2].custom_content.featured_image}');`"
    >
      <a :href="`/blog/${data[2].slug}`" class="absolute inset-0"></a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: false
    }
  }
}
</script>
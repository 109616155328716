<template>
  <main v-if="loaded" class="h-auto py-12">
    <div class="flex flex-wrap sm:flex-row items-center">
      <div class="w-full bg-black ratio1_1_2_1 relative bg-cover bg-center bg-no-repeat"
           :style="`background-image: url('${pageData.custom_content.header_image.featured_image}');`">
        <img :src="pageData.custom_content.header_image.case_logo_image" class="case-logo absolute w-auto h-full ">
      </div>
    </div>
    <div class="my-20 container mx-auto text-center px-4">
      <h1 class="font-bold text-2xl uppercase">
        <span v-html="pageData.custom_content.title"></span>
      </h1>
      <h2 class="text-xl">
        <span v-html="pageData.custom_content.subtitle"></span>
      </h2>
      <p class="w-full sm:w-2/3 mx-auto pt-8 case_content">
        <span v-html="pageData.custom_content.content"></span>
      </p>
      <p class="w-full sm:w-2/3 mx-auto pt-8 hashtags">
        <span v-html="pageData.custom_content.hashtags"></span>
      </p>
    </div>

    <div class="container mx-auto pb-12 sm:pb-24 px-4">
      <template v-for="row in pageData.custom_content.images_bottom">
        <div class="flex flex-wrap sm:flex-row" v-if="row.acf_fc_layout === '1_2-images'">
          <div class="w-full sm:w-1/2 ratio1_1 border-2 border-white bg-cover bg-center bg-no-repeat"
               :style="`background-image: url('${row['1_2-image-left']}');`">

          </div>
          <div class="w-full sm:w-1/2 ratio1_1 border-2 border-white bg-cover bg-center bg-no-repeat"
               :style="`background-image: url('${row['1_2-image-right']}');`">

          </div>
        </div>
        <div class="flex flex-wrap sm:flex-row" v-if="row.acf_fc_layout === '1_1-images'">
          <div class="w-full ratio2_1 border-2 border-white bg-cover bg-center bg-no-repeat"
               :style="`background-image: url('${row['1_1-image']}');`">

          </div>
        </div>
        <div class="hidden lg:flex flex-wrap sm:flex-row" v-if="row.acf_fc_layout === '1_1-videos'">
          <div class="w-full hidden lg:block">
            <div class="wrapper relative">
              <div class="iframe-overlay bg-black bg-opacity-25 absolute inset-0 h-full w-full"></div>
              <div class="frame-container">
                <iframe id="ytplayer" type="text/html" width="100%" height="650" :src="`https://www.youtube.com/embed/${row['1_1-video']}?rel=0&autoplay=1&controls=0&disablekb=1&showinfo=0&autohide=1&loop=1&playlist=${row['1_1-video']}&playsinline=1&mute=1`" allow="autoplay;" frameborder="0"></iframe>
              </div>
            </div>

          </div>
        </div>
      </template>
    </div>

    <div class="flex flex-row container mx-auto px-4">
      <div class="w-1/3 previous uppercase">
        <a v-if="currentPage !== 0 && typeof cases[currentPage - 1] !== 'undefined'"  :href="cases[currentPage - 1].slug">< <span class="hidden sm:inline-block prev-text">vorige</span></a>
      </div>
      <div class="w-1/3 summary uppercase text-center">
        <a href="/">overzicht</a>
      </div>
      <div class="w-1/3 next uppercase text-right">
        <a v-if="currentPage !== cases.length && typeof cases[currentPage + 1] !== 'undefined'" :href="cases[currentPage + 1].slug"><span class="hidden sm:inline-block next-text">volgende</span> ></a>
      </div>
    </div>
  </main>
</template>

<script>
import { getPostBySlug } from '@/services/Wordpress/Get'
import { mapGetters } from 'vuex'

export default {
  metaInfo: {
    title: 'Bekijk onze cases | Tikkl | Een grafisch bureau dat kietelt',
    meta: [
      { charset: 'utf-8' },
      {
        name: 'keywords',
        content: 'TIKKL, Uden'
      },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        hid: 'description',
        name: 'description',
        content: 'Wij zijn trots op onze doordachte huisstijlen en verrassende concepten - Tikkl - een grafisch bureau dat kietelt met doordachte huisstijlen en verrassende concepten'
      },
      {
        property: 'og:title',
        content: 'Bekijk onze cases | Tikkl | Een grafisch bureau dat kietelt'
      },
      {
        property: 'og:description',
        content: 'Wij zijn trots op onze doordachte huisstijlen en verrassende concepten - Tikkl - een grafisch bureau dat kietelt met doordachte huisstijlen en verrassende concepten'
      },
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico'
      }
    ],
    htmlAttrs: {
      lang: 'nl',
      amp: true
    }
  },
  data() {
    return {
      pageData: null,
      loaded: false,
      currentPage: null
    }
  },

  watch: {
    pageData() {
      this.loaded = true;
    }
  },

  computed: mapGetters(['globalValues','cases']),

  async created () {
    this.pageData = await getPostBySlug({ slug: this.$route.params.slug });
  },

  mounted() {
    this.setCurrentPage();
  },

  methods: {

    /**
     * Determine the currentPage based on the slug
     */
    setCurrentPage() {
      setTimeout(() => {
        Object.keys(this.cases).forEach(caseIndex => {
          if (this.cases[caseIndex].slug === this.$route.params.slug) {
            this.currentPage = parseInt(caseIndex);
          }
        });
      }, 1000);
    }
  }
}
</script>
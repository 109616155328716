<template>
  <div class="flex flex-wrap sm:flex-row">
    <div v-if="data[0]" class="order-2 sm:order-1 w-1/2 sm:w-1/4 ratio1_1 border-2 border-white bg-cover bg-center bg-no-repeat" :style="`background-image: url('${data[0].url}');`">

    </div>
    <div v-if="data[1]" class="order-1 sm:order-2 w-full sm:w-2/4 ratio2_1 border-2 border-white bg-cover bg-center bg-no-repeat" :style="`background-image: url('${data[1].url}');`">

    </div>
    <div v-if="data[2]" class="order-3 w-1/2 sm:w-1/4 ratio1_1 border-2 border-white bg-cover bg-center bg-no-repeat" :style="`background-image: url('${data[2].url}');`">

    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      required: false
    }
  }
}
</script>
<template>
  <main class="h-auto container mx-auto px-4 main">
    <div class="flex flex-wrap items-end py-6 sm:py-16">
      <div class="w-full sm:w-2/3">
        <h1 class="font-bold mb-4 text-2xl">
          <span v-html="pageData.title"></span>
        </h1>
        <p class="w-full sm:w-3/4">
          <span v-html="pageData.default_content"></span>
        </p>
      </div>
      <div class="w-full sm:w-1/3 text-right mt-8 sm:mt-0">
        <a :href="globalValues.social_links.linkedin" target="_blank">
          <i class="fab fa-linkedin text-3xl mr-4 cursor-pointer"></i>
        </a>
        <a :href="globalValues.social_links.facebook" target="_blank">
          <i class="fab fa-facebook-square text-3xl mr-4 cursor-pointer"></i>
        </a>
        <a :href="globalValues.social_links.instagram" target="_blank">
          <i class="fab fa-instagram-square text-3xl cursor-pointer"></i>
        </a>
      </div>
    </div>
    <div class="logos">
      <div v-for="row in logoRows">
        <first-logo-row :data="row.data" v-if="row.number === 1" />
        <second-logo-row  :data="row.data" v-if="row.number === 2" />
        <third-logo-row :data="row.data" v-if="row.number === 3" />
        <fourth-logo-row :data="row.data" v-if="row.number === 4" />
        <fifth-logo-row :data="row.data" v-if="row.number === 5" />
        <sixth-logo-row :data="row.data" v-if="row.number === 6" />
        <seventh-logo-row :data="row.data" v-if="row.number === 7" />
        <eight-logo-row :data="row.data" v-if="row.number === 8" />
      </div>
    </div>
  </main>
</template>

<script>
import firstLogoRow from '@/components/logo/firstLogoRow'
import secondLogoRow from '@/components/logo/secondLogoRow'
import thirdLogoRow from '@/components/logo/thirdLogoRow'
import fourthLogoRow from '@/components/logo/fourthLogoRow'
import fifthLogoRow from '@/components/logo/fifthLogoRow'
import sixthLogoRow from '@/components/logo/sixthLogoRow'
import seventhLogoRow from '@/components/logo/seventhLogoRow'
import eightLogoRow from '@/components/logo/eightLogoRow'
import {getPage, getPostById} from '@/services/Wordpress/Get'
import { mapGetters } from 'vuex'

export default {
  metaInfo: {
    title: "Wij zijn verliefd op logo's | Tikkl | Een grafisch bureau dat kietelt",
    meta: [
      { charset: 'utf-8' },
      {
        name: 'keywords',
        content: 'TIKKL, Uden'
      },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        hid: 'description',
        name: 'description',
        content: "Wij zijn verliefd op logo's - Tikkl - een grafisch bureau dat kietelt met doordachte huisstijlen en verrassende concepten"
      },
      {
        property: 'og:title',
        content: "Wij zijn verliefd op logo's | Tikkl | Een grafisch bureau dat kietelt"
      },
      {
        property: 'og:description',
        content: "Wij zijn verliefd op logo's - Tikkl - een grafisch bureau dat kietelt met doordachte huisstijlen en verrassende concepten"
      },
      { property: 'og:url', content: 'https://tikkl.nl/de-logos' }
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: '/favicon.ico'
      }
    ],
    htmlAttrs: {
      lang: 'nl',
      amp: true
    }
  },
  data() {
    return {
      logoRows: [],
      logoPosts: [],
      pageData: {
        title: '',
        default_content: '',
        custom_content: {},
        seo: {}
      }
    }
  },
  components: {
    firstLogoRow,
    secondLogoRow,
    thirdLogoRow,
    fourthLogoRow,
    fifthLogoRow,
    sixthLogoRow,
    seventhLogoRow,
    eightLogoRow
  },
  computed: mapGetters(['globalValues']),
  async created () {
    [this.pageData, this.logoPosts] = await Promise.all([
      getPage({ slug: this.$route.path }),
      getPostById({ id: '150' })
    ])

    this.calculateRows()
  },
  methods: {
    calculateRows() {
      let amountAllowedInRow = [4, 3, 3, 4, 3, 4, 3, 3]
      let whichRow = 0
      let amountInRow = 0
      let createNewRow = true
      let rowCounter = 0

      for (let i = 0; i < this.logoPosts.custom_content.logos.length; i++) {
        if (createNewRow) {
          this.logoRows.push({
            number: (whichRow + 1), data: []
          })
          createNewRow = false
        }

        this.logoRows[rowCounter].data.push(this.logoPosts.custom_content.logos[i])
        amountInRow++

        if (amountInRow === amountAllowedInRow[whichRow]) {
          createNewRow = true
          amountInRow = 0
          whichRow++
          rowCounter++

          if (whichRow === 8) {
            whichRow = 0
          }
        }
      }
    }
  }
}
</script>